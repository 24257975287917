import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { themeStyles, colors } from '../../utils/theme'
import TitleBar from '../../components/TitleBar'

const JobThumbnail = ({ job }) => (
  <div key={job.id}>
    <Link
      to={job.fields.slug}
      css={{textDecoration: 'none'}}
    >
      <h3 css={{color: colors.grey, fontWeight: 300}}>
        {job.frontmatter.title}
      </h3>
    </Link>
  </div>
)

class CareersPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges } = data.allMarkdownRemark
    const sfJobs = []
    const cmJobs = []
    edges.forEach(edge => {
      const loc = edge.node.frontmatter.location || ''
      if (loc.includes('San Francisco') || loc.includes('SF')) {
        sfJobs.push(edge.node)
      } else {
        cmJobs.push(edge.node)
      }
    })
    return (
      <Layout metadataTitle='LoanSnap - Careers' metadataDescription="Join us in improving American's financial situation.">
        <TitleBar
          title="Careers"
          links={[
            {text: 'San Francisco', link: '#san-francisco'},
            {text: 'Costa Mesa', link: '#costa-mesa'},
          ]}
          linkAlign="left"
        />
        <div css={[themeStyles.contentWidth, themeStyles.textPadding]}>
          <h2 id="san-francisco" css={[themeStyles.sectionHeader, {textAlign: 'left', color: colors.blueGreen, fontWeight: 300}]}>
            San Francisco
          </h2>
          {sfJobs && sfJobs.map((job, i) => <JobThumbnail job={job} key={i} />)}
          <h2 id="costa-mesa" css={[themeStyles.sectionHeader, {textAlign: 'left', color: colors.blueGreen, fontWeight: 300}]}>
            Costa Mesa
          </h2>
          {cmJobs && cmJobs.map((job, i) => <JobThumbnail job={job} key={i} />)}
        </div>

      </Layout>
    )
  }
}

export default CareersPage

export const pageQuery = graphql`
  query CareersPageQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "job-page" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            location
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`